// import { createStore } from 'redux'

// const initialState = {
//   sidebarShow: true,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store


import { configureStore } from "@reduxjs/toolkit"
import userReducer from "./redux/reducers/userReducer"
import sidebarReducer from "./redux/reducers/sidebarReducer"
import workReducer from "./redux/reducers/workReducer"

const store = configureStore({
  reducer: {
    user: userReducer,
    sidebar: sidebarReducer,
    work: workReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
})

export default store
