import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { login } from 'src/services/api'
import { useNavigate } from 'react-router-dom'
import Noty from 'noty';
import 'noty/lib/noty.css'; // Import Noty CSS
import 'noty/lib/themes/metroui.css';
import 'noty/lib/themes/sunset.css';
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { userLoginSuccess } from 'src/redux/reducers/userReducer'
import store from 'src/store'

const Login = () => {

  const navigate = useNavigate()
  const [signinState, setSigninState] = useState({
    email: '',
    password: ''
  })
  const [formErrors, setFormErrors] = useState({})
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      navigate('/dashboard'); // Redirect to the home page if user is logged in
    }
  }, [navigate]);

  const handleChange = (e) => {
    setSigninState({ ...signinState, [e.target.name]: e.target.value })
    clearError(e.target.name);
  }

  const handleLogin = () => {
    validateForm()
  }


  const validateForm = async () => {
    const errors = {};

    if (signinState.email.length === 0) {
      errors.email = 'Email Address cannot be empty';
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(signinState.email)) {
        errors.email = 'Please enter a valid email address';
      }
    }

    if (signinState.password.length === 0) {
      errors.password = 'Password Field cannot be empty';
    }

    if (Object.keys(errors).length === 0) {
      setLoading(true)
      await login(signinState)
        .then((response) => {
          if (response.data.status === 200) {
            setLoading(false)
            store.dispatch(userLoginSuccess(response.data.user))
            setFormErrors('')
            setSigninState({
              email: '',
              password: '',
            })
            setTimeout(() => {
              new Noty({
                type: 'success',
                text: response.data.message, // Alert message
                timeout: 2000, // Auto-dismiss after 5 seconds (adjust as needed)
                theme: 'metroui', // Choose a theme (e.g., mint, relax, bootstrap-v4, etc.)
                layout: 'topRight', // Display at the top-right corner
              }).show();
              localStorage.setItem('user', JSON.stringify(response.data.user))
              navigate('/dashboard')
            }, 500)
          } else {
            setLoading(false)
            new Noty({
              type: 'error',
              text: response.data.message, // Alert message
              timeout: 2000, // Auto-dismiss after 5 seconds (adjust as needed)
              theme: 'metroui', // Choose a theme (e.g., mint, relax, bootstrap-v4, etc.)
              layout: 'topRight', // Display at the top-right corner
            }).show();
          }
        })
    } else {
      setFormErrors(errors)
    }
  }

  // Function to clear the error for a specific field when it's focused
  const clearError = (fieldName) => {
    setFormErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: '' };
    });
  };


  const IconComponent = passwordVisible ? FaEye : FaEyeSlash;

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3" onChange={handleChange}>
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput name='email' value={signinState.email} placeholder="Username" autoComplete="username" />
                    </CInputGroup>
                    {formErrors.email && (
                      <div className='error-message'>
                        {formErrors.email}
                      </div>
                    )}
                    <CInputGroup className="mb-3" onChange={handleChange}>
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        name='password'
                        value={signinState.password}
                        type={passwordVisible ? 'test' : 'password'}
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                      <IconComponent
                        size={20}
                        style={{ position: 'absolute', right: '12px', top: '10px', zIndex: 999 }}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      />
                    </CInputGroup>
                    {formErrors.password && (
                      <div className='error-message'>
                        {formErrors.password}
                      </div>
                    )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" onClick={handleLogin}>
                          {loading ?
                            <CSpinner color="white" size="sm" />
                            :
                            'Login'
                          }
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" id='signup-card'>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to="/register">
                      <CButton id='signup-btn' color="primary" className="mt-3" active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
