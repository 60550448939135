import React, { useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CAvatar
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
// import { logo } from 'src/assets/brand/logo'
import logo from '../assets/images/react.83088efde08a5dedde9f.png'
import store from 'src/store'
import { setSidebar, selectSidebar } from 'src/redux/reducers/sidebarReducer'

const AppHeader = () => {
  // const dispatch = useDispatch()
  // const sidebarShow = useSelector((state) => state.sidebarShow)
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) {
      navigate('/');
    }
  }, []); // Empty dependency array ensures this effect runs only once

  const sidebarData = useSelector(selectSidebar)
  const sidebarShow = sidebarData.sidebarShow

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          // onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
          onClick={() => store.dispatch(setSidebar({ sidebarShow: !sidebarShow }))}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="d-md-none" to="/" style={{ marginRight: 'auto', marginLeft: 'unset' }}>
          <CAvatar src={logo} size="md" style={{ alignItems: 'unset' }} />
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to='/users' component={NavLink}>Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to='/jobs' component={NavLink}>Jobs</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to='/dashboardUsers' component={NavLink}>Dashboard Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to='/usersPayments' component={NavLink}>Payments</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to='/Notification' component={NavLink}>Notification</CNavLink>
          </CNavItem>
          {/* <CNavItem>
            <CNavLink to='/profile' component={NavLink}>Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          { /*<CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilBell} size="lg" />
            </CNavLink>
          </CNavItem>*/ }
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
