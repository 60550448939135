import React from 'react'
import { Navigate } from 'react-router-dom';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Users = React.lazy(() => import('./views/users/Users'))
const PostWidgets = React.lazy(() => import('./views/widgets/PostWidgets'))
const PostDetailWidgets = React.lazy(() => import('./views/widgets/PostDetailWidgets'))
const PostSingleWidgets = React.lazy(() => import('./views/widgets/PostSingleWidgets'))
const DashboardUsers = React.lazy(() => import('./views/dahboardUsers/DashboardUsers'))
const Profile = React.lazy(() => import('./views/profile/Profile'))
const EditAppUserProfile = React.lazy(() => import('./views/users/EditAppUserProfile'))
const Notification = React.lazy(() => import('./views/notifications/Notification'))
const Feedback = React.lazy(() => import('./views/notifications/Feedback'))
const Jobs = React.lazy(() => import('./views/jobs/Jobs'))
const SubscriptionUsers = React.lazy(() => import('./views/subscriptionUsers/SubscriptionUsers'))
const NotificationDetail = React.lazy(() => import('./views/notifications/NotificationDetails'))
const NotificationReply = React.lazy(() => import('./views/notifications/NotificationReply'))
const SecretCode = React.lazy(() => import('./views/secretcode/SecretCode'))
const PromoCode = React.lazy(() => import('./views/promocode/PromoCode'))
const PromoCodeDetail = React.lazy(() => import('./views/promocode/PromoCodeDetail'))
const AddProfession = React.lazy(() => import('./views/profession/AddProfession'))
const EditProfession = React.lazy(() => import('./views/profession/EditProfession'))
const ProfessionDetail = React.lazy(() => import('./views/profession/ProfessionDetail'))
const ReportUsers = React.lazy(() => import('./views/reports/ReportUsers'))
const ReportUserDetails = React.lazy(() => import('./views/reports/ReportUserDetails'))
const ReportPosts = React.lazy(() => import('./views/reports/ReportPosts'))
const ReportPostDetails = React.lazy(() => import('./views/reports/ReportPostDetails'))
const AddPromotions = React.lazy(() => import('./views/promotions/AddPromotions'))
const PromotionDetail = React.lazy(() => import('./views/promotions/PromotionDeatil'))
const EditPromotion = React.lazy(() => import('./views/promotions/EditPromotion'))

const UsersPayments = React.lazy(() => import('./views/subscriptionUsers/UsersPayments'))
const UserPaymentsCardDeatil = React.lazy(() => import('./views/subscriptionUsers/UserPaymentsCardDetail'))
const PaymentInfo = React.lazy(() => import('./views/subscriptionUsers/PaymentInfo'))
const LogInfo = React.lazy(() => import('./views/subscriptionUsers/LogInfo'))

const SendNotifications = React.lazy(() => import('./views/notifications/SendNotifications'))
//New User Add
const AddNewUser = React.lazy(() => import('./views/users/AddNewUser'))



const NotFound = () => {
  return <Navigate to="/404" />;
};

const routes = [
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/users', name: 'Users', element: Users },
  { path: '/postwidgets', name: 'Post Widgets', element: PostWidgets },
  { path: '/postwidgets/postdetailwidgets', name: 'Post Detail Widgets', element: PostDetailWidgets },
  { path: '/postsinglewidgets', name: 'Post Single Widgets', element: PostSingleWidgets },
  { path: '/dashboardUsers', name: 'Dashboard Users', element: DashboardUsers },
  { path: '/profile', name: 'Profile', element: Profile },
  { path: '/users/editappuserprofile', name: 'Edit App User Profile', element: EditAppUserProfile },
  { path: '/notification', name: 'Notification', element: Notification },
  { path: '/secretcode', name: 'Secretcode', element: SecretCode },
  { path: '/promocode', name: 'Promocode', element: PromoCode },
  { path: '/promocodedetail', name: 'PromocodeDetail', element: PromoCodeDetail },
  { path: '/addprofession', name: 'AddProfession', element: AddProfession },
  { path: '/editprofession', name: 'EditProfession', element: EditProfession },
  { path: '/professiondetail', name: 'ProfessionDetail', element: ProfessionDetail },
  //{ path: '/notification/feedback', name: 'Feedback', element: Feedback },
  { path: '/jobs', name: 'Jobs', element: Jobs },
  { path: '/subscriptionsUsers', name: 'Subscriptions Users', element: SubscriptionUsers },
  { path: '/Notification/:type', name: 'NotificationDetail', element: NotificationDetail }, // Add the route for NotificationDetail
  { path: '/Notification/Reply/:notificationId', name: 'NotificationReply', element: NotificationReply },
  // Reports
  { path: '/ReportUsers', name: 'Report Users', element: ReportUsers }, // Add the route for Report Users
  { path: '/ReportUsers/Details/:reportId', name: 'Report User Details', element: ReportUserDetails },
  { path: '/ReportPosts', name: 'Report Posts', element: ReportPosts },
  { path: '/ReportPosts/Details/:reportId', name: 'Report Post Details', element: ReportPostDetails },
  // Add Promotions
  { path: '/AddPromotions', name: 'Add Promotions', element: AddPromotions },
  { path: '/PromotionDetail', name: 'Promotion Detail', element: PromotionDetail },
  { path: '/PromotionDetail/editpromotion', name: 'Edit Promotion', element: EditPromotion },
  // Users Payments
  { path: '/usersPayments', name: 'Users Payments', element: UsersPayments },
  // { path: '/usersPayments/cardDetails', name: 'Card Details', element: UserPaymentsCardDeatil },
  // { path: '/usersPayments/cardDetails/paymentInfo', name: 'Payment Info', element: PaymentInfo },
  { path: '/usersPayments/paymentInfo', name: 'Payment Info', element: PaymentInfo },
  { path: '/usersPayments/cardDetails/paymentInfo/LogInfo', name: 'Log Info', element: LogInfo },
  // Send Notifications
  { path: '/sendNotifications', name: 'Send Notifications', element: SendNotifications },
  { path: '*', element: NotFound },
  //Add New User
  { path: '/users/addNewUser', name: 'Add New User', element: AddNewUser },
]

export default routes 
