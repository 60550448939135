import React from 'react'
import { useLocation } from 'react-router-dom'

import routes from '../routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import { NavLink, useNavigate } from 'react-router-dom'


const AppBreadcrumb = () => {

  const navigate = useNavigate()

  const currentLocation = useLocation().pathname

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  // const getBreadcrumbs = (location) => {
  //   const breadcrumbs = []
  //   location.split('/').reduce((prev, curr, index, array) => {
  //     const currentPathname = `${prev}/${curr}`
  //     const routeName = getRouteName(currentPathname, routes)
  //     routeName &&
  //       breadcrumbs.push({
  //         pathname: currentPathname,
  //         name: routeName,
  //         active: index + 1 === array.length ? true : false,
  //       })
  //     return currentPathname
  //   })
  //   return breadcrumbs
  // }


  const getBreadcrumbs = (location) => {
    const breadcrumbs = [];

    const path = location.split('/');
    path.reduce((prev, curr, index, array) => {
      // const currentPathname = `/${array[index]}`;
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes);
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        });
      return currentPathname;
    });

    return breadcrumbs;
  };


  const breadcrumbs = getBreadcrumbs(currentLocation)


  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
        onClick={(e) => {
          e.preventDefault()
          navigate('/')
        }}
      >Home</CBreadcrumbItem>
      {/* {breadcrumbs.map((breadcrumb, index) => (
          <CBreadcrumbItem key={index} active={breadcrumb.active}>
            {breadcrumb.active ? breadcrumb.name : <Link to={breadcrumb.pathname}>{breadcrumb.name}</Link>}
          </CBreadcrumbItem>
        ))}
      </CBreadcrumb> */}
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <CBreadcrumbItem style={{
            color: breadcrumb.active ? '' : 'blue',
            cursor: breadcrumb.active ? '' : 'pointer',
            textDecoration: breadcrumb.active ? '' : 'underline',

          }}
            // {...(breadcrumb.active ? { active: true } : { href: breadcrumb.pathname })}
            {...(breadcrumb.active ? { active: true } : '')}
            key={index}
            onClick={(e) => {
              if (!breadcrumb.active) {
                e.preventDefault();
                navigate(-1);
              }
            }}

          >
            {breadcrumb.name}
          </CBreadcrumbItem>
        )
      })}
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
