import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    postWorkData: [],
}

export const counterSlice = createSlice({
    name: 'work',
    initialState,
    reducers: {
        postWorkSuccess: (state, action) => {
            console.log('work post success data')
            state.postWorkData = action.payload
        },
        deletePostSuccess: (state, action) => {
            const deletedPostIndex = action.payload;
            state.postWorkData = state.postWorkData.filter((_, index) => index !== deletedPostIndex);
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    postWorkSuccess, deletePostSuccess
} = counterSlice.actions


export const stateWorkData = (state) => state.work

export default counterSlice.reducer