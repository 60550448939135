import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: ''
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSidebar: (state, action) => {
      console.log('in sidebar reducer')
      return { ...state, ...action.payload };
    },
  },
});

export const { setSidebar } = sidebarSlice.actions;

export const selectSidebar = (state) => state.sidebar;

export default sidebarSlice.reducer;
