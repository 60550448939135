import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler, CAvatar } from '@coreui/react'
// import CIcon from '@coreui/icons-react'
import { AppSidebarNav } from './AppSidebarNav'
// import { logoNegative } from 'src/assets/brand/logo-negative'
// import { sygnet } from 'src/assets/brand/sygnet'
import logo from '../assets/images/logo.png'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
// sidebar nav config
import navigation from '../_nav'
import { setSidebar, selectSidebar } from 'src/redux/reducers/sidebarReducer'
import store from 'src/store'
import { useSelector } from 'react-redux'

const AppSidebar = () => {

  // const dispatch = useDispatch()

  const sidebarData = useSelector(selectSidebar)
  const unfoldable = sidebarData.sidebarUnfoldable
  const sidebarShow = sidebarData.sidebarShow

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        // dispatch({ type: 'set', sidebarShow: visible })
        store.dispatch(setSidebar({ sidebarShow: visible }))
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/" id='brand'>

        <CAvatar className="sidebar-brand-full" size='md' src={logo} style={{ alignItems: 'unset' }} />
        <p className="sidebar-brand-full" style={{ margin: 'unset', fontWeight: 'bold' }}>Tradie Panel</p>
        <CAvatar className="sidebar-brand-narrow" size='sm' src={logo} style={{ alignItems: 'unset' }} />
        {/* <CIcon className="sidebar-brand-full" icon={logo} height={35} /> */}
        {/* <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        // onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
        onClick={() => store.dispatch(setSidebar({ sidebarUnfoldable: !unfoldable }))}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
