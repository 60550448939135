import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    sessionUser: {},
    appUserProfileData: {},
    secretCode: null,
    promoCode: null
}

export const counterSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userLoginSuccess: (state, action) => {
            console.log('user Login Success')
            state.sessionUser = action.payload
        },
        userLogoutSuccess: (state) => {
            console.log('user Logout Success')
            state.sessionUser = {}
        },
        setAppUserProfileData: (state, action) => {
            console.log('App user profile Logout Success')
            state.appUserProfileData = action.payload
        },
        setSecretCodeRequest: (state, action) => {
            console.log('Secret Code save request')
            state.secretCode = action.payload
        },
        setPromoCodeRequest: (state, action) => {
            console.log('Secret Code save request')
            state.promoCode = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    userLoginSuccess, userLogoutSuccess, setAppUserProfileData, setSecretCodeRequest, setPromoCodeRequest
} = counterSlice.actions


export const stateUserData = (state) => state.user

export default counterSlice.reducer