import React, { useState, useEffect } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilImage, cilLockLocked, cilUser } from '@coreui/icons'
import { registration } from 'src/services/api'
import { useNavigate } from 'react-router-dom'
import Noty from 'noty';
import 'noty/lib/noty.css'; // Import Noty CSS
import 'noty/lib/themes/metroui.css';
import 'noty/lib/themes/sunset.css';
import { FaEye, FaEyeSlash } from "react-icons/fa6";


const Register = () => {

  const navigate = useNavigate()

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      navigate('/dashboard'); // Redirect to the home page if user is logged in
    }
  }, [navigate]);

  const [signupState, setSignupState] = useState(
    {
      username: '',
      email: '',
      password: '',
      confirmpassword: '',
      image: ''
    }
  );
  const [formErrors, setFormErrors] = useState({});
  // const [permissionError, setPermissionError] = useState()
  const [selectedImage, setSelectedImage] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    // setPermissionError('')
    setSignupState({ ...signupState, [e.target.name]: e.target.value })
    clearError(e.target.name);
  };

  const handleChangee = (e) => {
    if (e.target.files) {
      setSelectedImage(e.target.files[0]);
      clearError(e.target.name);
    }
    setSignupState({
      ...signupState,
      image: e.target.files
    })

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    validateForm()
  }

  const validateForm = async () => {
    const errors = {};

    if (signupState.username.length === 0) {
      errors.username = 'Username cannot be empty'
    }

    if (signupState.email.length === 0) {
      errors.email = 'Email Address cannot be empty';
    } else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(signupState.email)) {
        errors.email = 'Please enter a valid email address';
      }
    }

    if (signupState.image.length === 0) {
      errors.image = 'Image field cannot be empty'
    }

    if (signupState.password.length === 0) {
      errors.password = 'Password Field cannot be empty';
    } else {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(signupState.password)) {
        errors.password = 'Password must contain at least one digit, one lowercase letter, one uppercase letter, and have a minimum length of 8 characters.';
      }
    }

    if (signupState.confirmpassword.length === 0) {
      errors.confirmpassword = 'Confirm Password Field cannot be empty';
    }

    if (Object.keys(errors).length === 0) {
      if (signupState.password !== signupState.confirmpassword) {
        // errors.notMatched = 'Password must be matched'
        // setFormErrors(errors)
        new Noty({
          type: 'error',
          text: 'Password must be matched', // Alert message
          timeout: 2000, // Auto-dismiss after 5 seconds (adjust as needed)
          theme: 'metroui', // Choose a theme (e.g., mint, relax, bootstrap-v4, etc.)
          layout: 'topRight', // Display at the top-right corner
        }).show();
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('username', signupState.username)
        formData.append('email', signupState.email)
        formData.append('image', signupState.image[0])
        formData.append('password', signupState.password)
        await registration(formData)
          .then((response) => {
            setLoading(false)
            if (response.data.status === 200) {
              setFormErrors('')
              setSelectedImage('')
              document.getElementById("myForm").reset();
              setSignupState({
                username: '',
                email: '',
                password: '',
                confirmpassword: '',
                image: ''
              })
              setTimeout(() => {
                new Noty({
                  type: 'success',
                  text: response.data.message, // Alert message
                  timeout: 2000, // Auto-dismiss after 5 seconds (adjust as needed)
                  theme: 'metroui', // Choose a theme (e.g., mint, relax, bootstrap-v4, etc.)
                  layout: 'topRight', // Display at the top-right corner
                }).show();
                navigate('/Login')
              }, 500)
            } else {
              setLoading(false)
              new Noty({
                type: 'warning',
                text: response.data.message, // Alert message
                timeout: 2000, // Auto-dismiss after 5 seconds (adjust as needed)
                theme: 'metroui', // Choose a theme (e.g., mint, relax, bootstrap-v4, etc.)
                layout: 'topRight', // Display at the top-right corner
              }).show();
            }
          })

      }
    } else {
      setFormErrors(errors)
    }
  }


  // Function to clear the error for a specific field when it's focused
  const clearError = (fieldName) => {
    setFormErrors((prevErrors) => {
      return { ...prevErrors, [fieldName]: '' };
    });
  };

  const IconComponent = passwordVisible ? FaEye : FaEyeSlash;
  const ConfirmIconComponent = confirmPasswordVisible ? FaEye : FaEyeSlash;

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              {!selectedImage ?
                ''
                :
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                  <img src={URL.createObjectURL(selectedImage)} style={{ width: 100, height: 100, float: 'left', borderRadius: '50px' }} alt='logo' />
                </div>
              }
              <CCardBody className="p-4">
                {formErrors.notMatched ?
                  <h5 className='error-message-pass'>{formErrors.notMatched}</h5>
                  : ''}
                <CForm onSubmit={handleSubmit} encType="multipart/form-data" method='post' action='' id="myForm">
                  <h1>Register</h1>
                  <p className="text-medium-emphasis">Create your account</p>
                  <CInputGroup className="mb-3" onChange={handleChange}>
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      value={signupState.username}
                      name='username'
                      placeholder="Username"
                      autoComplete="username" />
                  </CInputGroup>
                  {formErrors.username && (
                    <div className='error-message'>
                      {formErrors.username}
                    </div>
                  )}
                  <CInputGroup className="mb-3" onChange={handleChange}>
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      name='email'
                      value={signupState.email}
                      placeholder="Email"
                      autoComplete="email" />
                  </CInputGroup>
                  {formErrors.email && (
                    <div className='error-message'>
                      {formErrors.email}
                    </div>
                  )}
                  <CInputGroup className="mb-3" onChange={handleChangee}>
                    <CInputGroupText>
                      <CIcon icon={cilImage} />
                    </CInputGroupText>
                    <CFormInput
                      type='file'
                      name='image'
                      id='image'
                    />
                  </CInputGroup>
                  {formErrors.image && (
                    <div className='error-message'>
                      {formErrors.image}
                    </div>
                  )}
                  <CInputGroup className="mb-3" onChange={handleChange}>
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      value={signupState.password}
                      name='password'
                      type={passwordVisible ? 'text' : 'password'}
                      placeholder="Password"
                      autoComplete="new-password"
                    />

                    <IconComponent
                      size={20}
                      style={{ position: 'absolute', right: '12px', top: '10px', zIndex: 999 }}
                      onClick={() => setPasswordVisible(!passwordVisible)}
                    />

                  </CInputGroup>
                  {formErrors.password && (
                    <div className='error-message'>
                      {formErrors.password}
                    </div>
                  )}
                  <CInputGroup className="mb-3" onChange={handleChange}>
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      name='confirmpassword'
                      value={signupState.confirmpassword}
                      type={confirmPasswordVisible ? 'text' : 'password'}
                      placeholder="Repeat password"
                      autoComplete="new-password"
                    />
                    <ConfirmIconComponent
                      size={20}
                      style={{ position: 'absolute', right: '12px', top: '10px', zIndex: 999 }}
                      onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                    />
                  </CInputGroup>
                  {formErrors.confirmpassword && (
                    <div className='error-message'>
                      {formErrors.confirmpassword}
                    </div>
                  )}
                  <CCol xs={12} className="text-right" style={{ display: 'flex', justifyContent: 'end', padding: '0 5px' }}>
                    <CButton color="link" className="px-0" onClick={() => navigate('/')}>
                      Back to login
                    </CButton>
                  </CCol>
                  <div className="d-grid">
                    <CButton type="submit" color="success" style={{ color: 'white' }}>
                      {loading ?
                        <CSpinner color="white" size="sm" />
                        :
                        'Create Account'
                      }
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div >
  )
}

export default Register
